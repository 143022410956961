<template>
  <!-- Text input - default -->
  <v-text-field
    v-if="!field.type || field.type === 'text'"
    v-bind="$attrs"
    :value="field.value"
    :label="field.label"
    :prepend-icon="field.icon"
    :prefix="field.prefix"
    :suffix="field.suffix"
    @change="onChange"
    dense
    hide-details="auto"
    color="primary darken-1"
  />

  <!-- Number input -->
  <v-text-field
    v-else-if="field.type === 'number'"
    v-bind="$attrs"
    :value="Number(field.value)"
    :label="field.label"
    :min="field.min"
    :max="field.max"
    :step="field.step"
    :prepend-icon="field.icon"
    :prefix="field.prefix"
    :suffix="field.suffix"
    @change="(v) => onChange(Number(v))"
    dense
    hide-details="auto"
    type="number"
    color="primary darken-1"
  />

  <!-- Select input -->
  <v-select
    v-else-if="field.type === 'select' && field.options"
    v-bind="$attrs"
    :items="field.options"
    :value="field.value"
    :label="field.label"
    :clearable="!field.required"
    :prepend-icon="field.icon"
    :prefix="field.prefix"
    :suffix="field.suffix"
    @change="onChange"
    dense
    hide-details="auto"
    color="primary darken-1"
    item-color="primary darken-1"
  />

  <!-- Textarea input -->
  <v-textarea
    v-else-if="field.type === 'textarea'"
    :value="field.value"
    :label="field.label"
    :prepend-icon="field.icon"
    :prefix="field.prefix"
    :suffix="field.suffix"
    @change="onChange"
    auto-grow
    color="primary darken-1"
    dense
    hide-details="auto"
    outlined
    rows="1"
  />

  <!-- Radio input -->
  <!-- Checkbox input -->
  <!-- Date input -->

  <!-- Datetime input -->
  <pibot-datetime-picker
    v-else-if="field.type === 'datetime'"
    v-bind="$attrs"
    :datetime="field.value"
    :label="field.label"
    :prepend-icon="field.icon"
    :prefix="field.prefix"
    :suffix="field.suffix"
    @change="onChange"
    dense
    hide-details="auto"
    color="primary darken-1"
  />

  <v-alert v-else type="error" dense icon="error">
    Unsupported input type "{{ field.type }}"
  </v-alert>
</template>

<script>
export default {
  name: 'pibot-form-field',
  inheritAttrs: false,
  components: {
    'pibot-datetime-picker': () => import('@/components/form-components/DatetimePicker')
  },
  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({
        type: 'text',
        label: 'Missing required prop: "field"',
        icon: 'error',
        prefix: 'error',
        suffix: 'error',
        required: false,
        options: []
      })
    }
  },
  methods: {
    onChange (value) {
      this.$emit('change', { field: this.field, value })
    }
  }
}
</script>
